import React from 'react'
import { artists } from '../../../data/artistes'
import { Link } from 'react-router-dom'

function MainArtiste() {
  return (
    <div className=''>
        <div className='relative flex sm:block'>
            <div className='flex animate-[scroll1_10s_linear_infinite] sm:hidden'>
                {
                    artists.concat(artists).map((artiste, index) => {
                        return (
                            <div key={index} className='w-[20rem] p-4 font-inter uppercase'>
                                <img className='' src={artiste.img} alt=""/>
                                <div className='text-xl'>{artiste.name}</div>
                                <div className='text-gray-600 text-lg'>{artiste.role}</div>
                            </div>
                        )
                    })
                }
            </div>
            {/* No infinite scroll version */}
            <div className='hidden sm:flex justify-center gap-14 items-center'>
                {
                    artists.map((artiste, index) => {
                        return (
                            <div key={index} className='w-[20rem] font-inter uppercase'>
                                <img className='' src={artiste.img} alt=""/>
                                <div className='text-xl'> <Link to="/artistes">{artiste.name}</Link></div>
                                <div className='text-gray-600 text-lg'>{artiste.role}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </div>
  )
}

export default MainArtiste