import React, { useState } from 'react';

const CircularText = ({ text }) => {

    const [start, setStart] = useState(false);

    useState(() => {
        setInterval(() => {
            setStart(!start);
        }, 1000);
    }, [])
  return (
    // <div className="flex items-center justify-center w-48 h-48 rounded-full">
      <div className="relative w-full h-full right-[7rem] md:right-[10rem] xl:right-[12rem] ">
        {text.split('').map((char, index) => (
          <span
            key={index}
            className={'h-[80px] absolute top-0 left-0 w-[20px] md:h-[120px] md:text-2xl xl:h-[160px] xl:text-3xl'}
            style={{
                transformOrigin: 'bottom center',
                transform: start ? `rotate(${(index * (360 / text.length))+360}deg)` : `rotate(${index * (360 / text.length)}deg)`,
                transition: 'transform 1s ease-in-out',
            }}
        >
            {char}
          </span>
        ))}
      </div>
    // </div>
  );
};

export default CircularText;
