import React, {useState} from 'react'
import { artists } from '../../data/artistes';
import CardArtist from './CardArtist/CardArtist';
import FicheArtiste from '../Home/FicheArtiste/FicheArtiste';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


function Artiste() {
    const [artist, setArtist] = useState(null);
  return (
    <div>
        {artist === null &&
        <div className='w-screen min-h-screen flex flex-col justify-center items-center'>
            <div className="col-span-5 text-lg text-gray-800 hidden lg:block my-6">
                <div className="text-lg text-gray-800 flex gap-6 items-center cursor-pointer w-fit" >
                    <Link to='/'>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                        <span>Back</span>
                    </Link>
                </div>
            </div>
            <p className='text center uppercase text-xl font-semibold font-inter' >Ils exposeront</p>
            <div className='flex justify-center items-center gap-6 flex-wrap'>
                {
                    artists.map((artiste, index) => {
                        return (
                            <CardArtist artiste={artiste} key={index} setArtist={()=>{setArtist(artiste)}}/>
                        )
                    })
                }
            </div>
        </div>}
        {artist && <div>
            <FicheArtiste artiste={artist} back={()=>{setArtist(null)}}/>
        </div>}
    </div>
  )
}

export default Artiste