import React from 'react'

import {_05_12_19, _08_10_21, _23_04_22, _23_05_22, _25_09_20, _26_03_21} from './images'

function Gallery() {
    
  return (
    <div>
        <div>
            <div className='text-center font-semibold font-inter my-6 text-3xl'>
                De nos precedents evenements
            </div>
        </div>

        <div className=''>
            <div className='mb-4 text-2xl font-inter uppercase'>
                05/12/19: Dominique ZINKPE
            </div>
            <div className='relative flex'>
                <div className='flex animate-[scroll1_20s_linear_infinite]'>
                    {
                        _05_12_19.concat(_05_12_19).concat(_05_12_19).map((img, index) => {
                            return (
                                <div key={index} className='w-[24rem] p-4'>
                                    <img className='' src={img} alt=""/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
        
        <div>
            <div>
                <div className='mb-4 text-2xl font-inter uppercase'>
                    26/03/21: Yao FOFO Sewenou & Sebastien BOKO
                </div>
            </div>
            <div className='relative flex'>
                <div className='flex animate-[scroll1_25s_linear_infinite]'>
                    {
                        _26_03_21.concat(_26_03_21).map((img, index) => {
                            return (
                                <div key={index} className='w-[24rem] p-4'>
                                    <img className='' src={img} alt=""/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </div>

        <div>
            <div>
                <div className='mb-4 text-2xl font-inter uppercase'>
                    25/09/20: Souleymane Konate & K. Aziangue
                </div>
            </div>
            <div className='relative flex'>
                <div className='flex animate-[scroll1_40s_linear_infinite]'>
                    {
                        _25_09_20.concat(_25_09_20).map((img, index) => {
                            return (
                                <div key={index} className='w-[24rem] p-4'>
                                    <img className='' src={img} alt=""/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>

        <div>
            <div>
                <div className='mb-4 text-2xl font-inter uppercase'>
                    08/10/21: Armand Boula & Nathanael Vodohoue
                </div>
            </div>
            <div className='relative flex'>
                <div className='flex animate-[scroll1_50s_linear_infinite]'>
                    {
                        _08_10_21.concat(_08_10_21).map((img, index) => {
                            return (
                                <div key={index} className='w-[24rem] p-4'>
                                    <img className='' src={img} alt=""/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>  

        </div>

        <div>
            <div>
                <div className='mb-4 text-2xl font-inter uppercase'>
                    23/04/22: Gerard Quenum & Remy Samuz
                </div>
            </div>
            
            <div className='relative flex'>
                <div className='flex animate-[scroll1_60s_linear_infinite]'>
                    {
                        _23_04_22.concat(_23_04_22).map((img, index) => {
                            return (
                                <div key={index} className='w-[24rem] p-4'>
                                    <img className='' src={img} alt=""/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>

        <div>
            <div>
                <div className='mb-4 text-2xl font-inter uppercase'>
                    23/05/22: Ariana Yoti Diaz
                </div>
            </div>
            <div className='relative flex'>
                <div className='flex animate-[scroll1_70s_linear_infinite]'>
                    {
                        _23_05_22.concat(_23_05_22).map((img, index) => {
                            return (
                                <div key={index} className='w-[24rem] p-4'>
                                    <img className='' src={img} alt=""/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Gallery