import { React } from "react";
import  ContactRow  from "./ContactRow";
import { faEnvelope, faPhone, faLocationDot, faCreditCard } from '@fortawesome/free-solid-svg-icons';

function Contact() {
	  return (
	<div className="max-w-[90rem] mx-auto flex-col gap-2 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-36 md:gap-y-10 my-8" >
		<ContactRow icon={faEnvelope} text="oliartbenin@yahoo.com"/>
		<ContactRow icon={faLocationDot} text="Abidjan, Côte d'Ivoire"/>
		<ContactRow icon={faPhone} text="+225 07 57 82 36 46"/>
		<ContactRow icon={faCreditCard} text="www.oliart.com"/>
	</div>
  );

}

export default Contact