import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';
import Home from './views/Home/Home';
import Olivia from './views/Olivia/Olivia';
import Root from './views/root/Root';
import Artiste from './views/Artiste/Artiste';
import Gallery from './views/Gallery/Gallery';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        children: [
            { path: '/', element: <Home /> },
            { path: '/artistes', element: <Artiste />},
            { path: '/olivia', element: <Olivia /> },
            { path: '/galerie', element: <Gallery/>}
        ]
    }
]);

function App() {
  return (
    <div className="overflow-x-hidden min-h-screen">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
