import React, {useState} from 'react'

function NextEvent() {

    const [hide,setHide] = useState(false);

  return (
    <div className='mx-4 mt-[12rem] mb-8 flex justify-center items-center'>
        <div className='max-w-[60rem]'>
            <div className='flex bg-white'>
                <div className='flex flex-col leading-[12rem] relative bottom-20'>
                    <span className='font-inter text-[12rem] lg:text-[16rem] text-stroke-0-5 text-white'>23</span>
                </div>
                <div className='self-end '>
                    <span className='font-inter text-[6rem] break-all leading-[7rem] lg:text-[11rem]'>Fevrier</span>
                </div>
            </div>
            <div className='flex items-center gap-2 py-4 bg-white'>
                <span className='inline-block w-6 h-6 rounded-full bg-black'></span>
                <span className='text-xl font-inter'>Abidjan, Cocodi</span>
            </div>
            <div className='relative border-t border-black'>
                <div className='absolute flex left-1/2 justify-center items-center h-[1px]'>
                    <span className='w-4 h-4 bg-black text-white flex justify-center items-center rounded-full cursor-pointer' onClick={()=>{setHide(!hide)}}>+</span>
                </div>
                <div className={`py-4 relative -z-30 transition ease-in ${hide ? '-translate-y-full' : ''}`}>
                    <p className='font-inter'>
                    Pour sa prochaine exposition, prévue le 23 février dans l'intimité de sa demeure, Olivia De Backker  promet une expérience artistique captivante et immersive. Cette fois-ci, elle mettra en vedette les œuvres fascinantes de deux artistes de renom : Dominique Zinkpè et Tchif. Les visiteurs auront l'opportunité rare de plonger dans l'univers artistique distinctif de ces deux créateurs exceptionnels, explorant les thèmes envoûtants, les motifs vibrants et les histoires évocatrices qui habitent leurs œuvres. Dans l'atmosphère chaleureuse et accueillante de sa maison transformée en galerie éphémère, Olivia De Backker  invite les amateurs d'art à découvrir et à célébrer la richesse de la création contemporaine africaine, tout en offrant un espace propice à la contemplation et à la conversation inspirée.
                    </p>
                </div>
            </div>
        </div>

    </div>
  )
}

export default NextEvent