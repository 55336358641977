import React from "react";
import Tag from "./Tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

function FicheArtiste({artiste, back}) {
	return (
		<div className="mx-6 my-12  lg:grid grid-cols-5 ">
			{/* This is a component describing an artist  */}

            <div className="col-span-5 text-lg text-gray-800">
                <div className="text-lg text-gray-800 flex gap-6 items-center cursor-pointer w-fit" onClick={()=>{back()}}>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                    <span>Back</span>
                </div>
            </div>

			<div className="flex justify-center col-span-2" >
			{/* image */}
                <div className="w-48 h-48 md:w-[18rem] md:h-[18rem] rounded-full overflow-hidden">
                    <img src={artiste.img} alt="Artiste" className="" />
                </div>
			</div>

            <div className="col-span-3">
                {/* artiste name */}
                <div className="text-center text-3xl font-light my-5" >
                    {artiste.name}
                </div>

                {/* tags */}
                <div className="flex flex-row flex-wrap gap-x-4 gap-y-4 items-center justify-center mb-11 ml-1" >
                    {artiste.label.map((tag, index) => {
                        return (
                            <Tag key={index} text={tag} />
                        )
                    })}
                </div>

                {/* description */}
                <div className="text-center text-lg font-light my-5" >
                    <div className="my-4">
                        <h2 className="font-bold mb-2 text-xl">Parcours</h2>
                        <p>{artiste.parcours}</p>
                    </div>
                    <div className="my-4">
                        <h2 className="font-bold mb-2 text-xl">Style</h2>
                        <p>{artiste.style}</p>
                    </div>
                    <div className="my-4">
                        <h2 className="font-bold mb-2 text-xl">Vision Artistique</h2>
                        <p>{artiste.vision}</p>
                    </div>
                </div>
            </div>


            <div className="col-span-5 flex justify-center items-center gap-12 flex-wrap mx-8">
                {artiste.oeuvres.map((oeuvre, index) => {
                    return (
                        <img className="sm:max-w-[38rem]" key={index} src={oeuvre} alt={`oeuvres${index}`} />
                    )
                })}
            </div>


		</div>
	);
}

export default FicheArtiste;