import React from 'react'
import olivia from '../../../assets/images/owner_portrait.jpeg'
import { Link } from 'react-router-dom'

function OliviaCard() {
  return (
    <Link to="/olivia">
        <div className='hover:scale-[1.05] transition-all ease-in-out flex flex-col justify-center items-center max-w-[55rem] w-[calc(100vw-2rem)] bg-[#eee] p-8 mx-auto gap-10 my-12 md:grid grid-cols-5'>
            <div className='col-span-2 flex justify-center items-center'>
                <div className='w-56 h-56 rounded-full overflow-hidden '>
                    <img className='w-56' src={olivia} alt="" srcset="" />
                </div>
            </div>
            <div className='text-center flex flex-col gap-4 col-span-3'>
                <h1 className='text-2xl'>Olivia De Backker </h1>
                <h2 className='text-xl text-gray-600'>Galleriste</h2>
                <p className='text-gray-900'>Olivia De Backker  incarne l'essence même de l'art africain contemporain, en tant que galeriste passionnée originaire du Bénin. À travers ses expositions, elle crée des expériences immersives qui transcendent les frontières de l'esthétique conventionnelle, mettant en lumière les talents émergents et établis du continent. Son engagement envers l'excellence artistique, son sens aigu des couleurs et sa fascination pour les dimensions religieuses et mystiques de l'art font d'elle une figure incontournable dans le paysage culturel africain.</p>
            </div>
        </div>
    </Link>
  )
}

export default OliviaCard