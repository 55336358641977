import React from 'react'
import oliviafull from '../../assets/images/oliviafull.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

function Olivia() {
  return (
    <div className='my-8 mx-4 font-inter text-justify lg:grid grid-cols-5'>
        <div className="col-span-5 text-lg text-gray-800 hidden lg:block my-6">
            <div className="text-lg text-gray-800 flex gap-6 items-center cursor-pointer w-fit" >
                <Link to='/'>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                    <span>Back</span>
                </Link>
            </div>
        </div>
        <div className='col-span-2'>
            <div className='flex justify-center items-center'>
                <img className='max-w-[20rem] lg:max-w-[26rem]' src={oliviafull} alt="Olivia De Backker" />
            </div>
        </div>

        <div className='col-span-3'>
            <h1 className='text-center lg:text-start font-semibold text-2xl my-4 lg:my-0 lg:mb-4'>Olivia De Backker : Gardienne de l'Âme Artistique Africaine</h1>

            <div className='my-6'>
                <h2 className='text-lg mb-3'>L'Émergence d'une Passion: Origines et Parcours</h2>
                <p className='text-gray-900'>Olivia De Backker  incarne l'essence même de l'art dans sa forme la plus authentique et diversifiée. Originaire du Bénin, son parcours est intrinsèquement lié à sa passion pour l'art, une passion qui transcende les frontières et les catégories conventionnelles. En tant que galeriste, elle s'est imposée comme une figure influente dans le monde de l'art contemporain africain.</p>
            </div>
            <div className='my-6'>
                <h2 className='text-lg mb-3'>Au-Delà de l'Exposition: Créer des Expériences Artistiques Immersives</h2>
                <p className='text-gray-900'>Lorsque l'on pénètre dans sa galerie, on est accueilli par un mélange enivrant de couleurs, de formes et de textures qui racontent des histoires, évoquent des émotions et invitent à la réflexion. Olivia De Backker  a un don pour découvrir et mettre en lumière les talents émergents ainsi que les artistes établis, en particulier ceux issus de l'Afrique, qui apportent un regard unique sur le monde à travers leur art.</p>
            </div>
            <div className='my-6'>
                <h2 className='text-lg mb-3'>Une Voix pour l'Afrique: La Promotion des Talents Contemporains</h2>
                <p className='text-gray-900'>Son approche de l'art va bien au-delà de la simple exposition. Elle crée des expériences immersives où les visiteurs sont invités à explorer et à interagir avec les œuvres, les aidant ainsi à découvrir comment ces pièces pourraient enrichir leur propre espace de vie. Ses expositions, organisées deux à trois fois par an, sont des événements très attendus où l'on découvre un éventail diversifié d'expressions artistiques, allant de la peinture à la sculpture en passant par d'autres formes d'art contemporain.</p>
            </div>
            <div className='my-6'>
                <h2 className='text-lg mb-3'>Collaboration avec les Grands: Un Engagement envers l'Excellence Artistique</h2>
                <p className='text-gray-900'>Olivia De Backker  a déjà collaboré avec des artistes renommés tels que Dominique Zinkpè, une collaboration qui témoigne de son engagement envers l'excellence artistique et son désir de promouvoir des voix uniques et visionnaires. Sa sensibilité aux couleurs et sa fascination pour les dimensions religieuses et mystiques de l'art se reflètent dans ses choix curatoriaux, créant ainsi des expositions qui transcendent les limites de l'esthétique pour toucher l'âme même de ceux qui les contemplent.</p>
            </div>
            <div className='my-6'>
                <h2 className='text-lg mb-3'>Au Service de l'Art Africain: Ambassadrice Culturelle et Créatrice d'Espaces Sacrés</h2>
                <p className='text-gray-900'>Au-delà de son rôle de galeriste, Olivia De Backker  est une véritable ambassadrice de la culture et de l'art africains, œuvrant inlassablement pour élargir les horizons artistiques et favoriser la compréhension et l'appréciation de la richesse et de la diversité de la création contemporaine du continent. Son dévouement à son métier et sa capacité à transformer des espaces en véritables sanctuaires artistiques font d'elle bien plus qu'une simple galeriste, mais une véritable gardienne de l'âme créative de l'Afrique.</p>
            </div>
        </div>
    </div>
  )
}

export default Olivia