import image1 from '../../assets/images/gallery/23.04.22/Dropped image 1.png';
import image2 from '../../assets/images/gallery/23.04.22/Dropped image 2.png';
import image3 from '../../assets/images/gallery/23.04.22/Dropped image 3.png';
import image4 from '../../assets/images/gallery/23.04.22/Dropped image 4.png';
import image5 from '../../assets/images/gallery/23.04.22/Dropped image 5.png';
import image6 from '../../assets/images/gallery/23.04.22/Dropped image 6.png';
import image7 from '../../assets/images/gallery/23.04.22/Dropped image 7.png';
import image8 from '../../assets/images/gallery/23.04.22/Dropped image 8.png';
import image9 from '../../assets/images/gallery/23.04.22/Dropped image 9.png';
import image10 from '../../assets/images/gallery/23.04.22/Dropped image 10.png';
import image11 from '../../assets/images/gallery/23.04.22/Dropped image 11.png';
import image12 from '../../assets/images/gallery/23.04.22/Dropped image 12.png';
import image13 from '../../assets/images/gallery/23.04.22/Dropped image 13.png';
import image14 from '../../assets/images/gallery/23.04.22/Dropped image 14.png';
import image15 from '../../assets/images/gallery/23.04.22/Dropped image 15.png';
import image16 from '../../assets/images/gallery/23.04.22/Dropped image 16.png';
import image17 from '../../assets/images/gallery/23.04.22/Dropped image 17.png';
import image18 from '../../assets/images/gallery/23.04.22/Dropped image 18.png';
import image19 from '../../assets/images/gallery/23.04.22/Dropped image 19.png';
import image20 from '../../assets/images/gallery/23.04.22/Dropped image 20.png';
import image21 from '../../assets/images/gallery/23.04.22/Dropped image 21.png';
import image22 from '../../assets/images/gallery/23.04.22/Dropped image 22.png';
import image23 from '../../assets/images/gallery/23.04.22/Dropped image 23.png';
import image24 from '../../assets/images/gallery/23.04.22/Dropped image 24.png';
import image from '../../assets/images/gallery/23.04.22/Dropped image.png';


import image25 from '../../assets/images/gallery/23.05.22/Dropped image 25.png';
import image26 from '../../assets/images/gallery/23.05.22/Dropped image 26.png';
import image27 from '../../assets/images/gallery/23.05.22/Dropped image 27.png';
import image28 from '../../assets/images/gallery/23.05.22/Dropped image 28.png';
import image29 from '../../assets/images/gallery/23.05.22/Dropped image 29.png';
import image30 from '../../assets/images/gallery/23.05.22/Dropped image 30.png';
import image31 from '../../assets/images/gallery/23.05.22/Dropped image 31.png';
import image32 from '../../assets/images/gallery/23.05.22/Dropped image 32.png';
import image33 from '../../assets/images/gallery/23.05.22/Dropped image 33.png';
import image34 from '../../assets/images/gallery/23.05.22/Dropped image 34.png';
import image35 from '../../assets/images/gallery/23.05.22/Dropped image 35.png';
import image36 from '../../assets/images/gallery/23.05.22/Dropped image 36.png';
import image37 from '../../assets/images/gallery/23.05.22/Dropped image 37.png';
import image38 from '../../assets/images/gallery/23.05.22/Dropped image 38.png';
import image39 from '../../assets/images/gallery/23.05.22/Dropped image 39.png';
import image40 from '../../assets/images/gallery/23.05.22/Dropped image 40.png';
import image41 from '../../assets/images/gallery/23.05.22/Dropped image 41.png';
import image42 from '../../assets/images/gallery/23.05.22/Dropped image 42.png';
import image43 from '../../assets/images/gallery/23.05.22/Dropped image 43.png';
import image44 from '../../assets/images/gallery/23.05.22/Dropped image 44.png';
import image45 from '../../assets/images/gallery/23.05.22/Dropped image 45.png';
import image46 from '../../assets/images/gallery/23.05.22/Dropped image 46.png';
import image47 from '../../assets/images/gallery/23.05.22/Dropped image 47.png';
import image48 from '../../assets/images/gallery/23.05.22/Dropped image 48.png';
import image49 from '../../assets/images/gallery/23.05.22/Dropped image 49.png';
import image50 from '../../assets/images/gallery/23.05.22/Dropped image 50.png';
import image51 from '../../assets/images/gallery/23.05.22/Dropped image 51.png';

import image52 from '../../assets/images/gallery/08.10.21/Dropped image 52.png';
import image53 from '../../assets/images/gallery/08.10.21/Dropped image 53.png';
import image54 from '../../assets/images/gallery/08.10.21/Dropped image 54.png';
import image55 from '../../assets/images/gallery/08.10.21/Dropped image 55.png';
import image56 from '../../assets/images/gallery/08.10.21/Dropped image 56.png';
import image57 from '../../assets/images/gallery/08.10.21/Dropped image 57.png';
import image58 from '../../assets/images/gallery/08.10.21/Dropped image 58.png';
import image59 from '../../assets/images/gallery/08.10.21/Dropped image 59.png';
import image60 from '../../assets/images/gallery/08.10.21/Dropped image 60.png';
import image61 from '../../assets/images/gallery/08.10.21/Dropped image 61.png';
import image62 from '../../assets/images/gallery/08.10.21/Dropped image 62.png';
import image63 from '../../assets/images/gallery/08.10.21/Dropped image 63.png';
import image64 from '../../assets/images/gallery/08.10.21/Dropped image 64.png';
import image65 from '../../assets/images/gallery/08.10.21/Dropped image 65.png';
import image66 from '../../assets/images/gallery/08.10.21/Dropped image 66.png';
import image67 from '../../assets/images/gallery/08.10.21/Dropped image 67.png';
import image68 from '../../assets/images/gallery/08.10.21/Dropped image 68.png';
import image69 from '../../assets/images/gallery/08.10.21/Dropped image 69.png';
import image70 from '../../assets/images/gallery/08.10.21/Dropped image 70.png';
import image71 from '../../assets/images/gallery/08.10.21/Dropped image 71.png';

import image72 from '../../assets/images/gallery/26.03.21/Dropped image 72.png';
import image73 from '../../assets/images/gallery/26.03.21/Dropped image 73.png';
import image74 from '../../assets/images/gallery/26.03.21/Dropped image 74.png';
import image75 from '../../assets/images/gallery/26.03.21/Dropped image 75.png';
import image76 from '../../assets/images/gallery/26.03.21/Dropped image 76.png';
import image77 from '../../assets/images/gallery/26.03.21/Dropped image 77.png';
import image78 from '../../assets/images/gallery/26.03.21/Dropped image 78.png';
import image79 from '../../assets/images/gallery/26.03.21/Dropped image 79.png';

import image80 from '../../assets/images/gallery/25.09.20/Dropped image 80.png';
import image81 from '../../assets/images/gallery/25.09.20/Dropped image 81.png';
import image82 from '../../assets/images/gallery/25.09.20/Dropped image 82.png';
import image83 from '../../assets/images/gallery/25.09.20/Dropped image 83.png';
import image84 from '../../assets/images/gallery/25.09.20/Dropped image 84.png';
import image85 from '../../assets/images/gallery/25.09.20/Dropped image 85.png';
import image86 from '../../assets/images/gallery/25.09.20/Dropped image 86.png';
import image87 from '../../assets/images/gallery/25.09.20/Dropped image 87.png';
import image88 from '../../assets/images/gallery/25.09.20/Dropped image 88.png';
import image89 from '../../assets/images/gallery/25.09.20/Dropped image 89.png';
import image90 from '../../assets/images/gallery/25.09.20/Dropped image 90.png';
import image91 from '../../assets/images/gallery/25.09.20/Dropped image 91.png';
import image92 from '../../assets/images/gallery/25.09.20/Dropped image 92.png';
import image93 from '../../assets/images/gallery/25.09.20/Dropped image 93.png';

import image94 from '../../assets/images/gallery/05.12.19/Dropped image 94.png';
import image95 from '../../assets/images/gallery/05.12.19/Dropped image 95.png';
import image96 from '../../assets/images/gallery/05.12.19/Dropped image 96.png';
import image97 from '../../assets/images/gallery/05.12.19/Dropped image 97.png';
import image98 from '../../assets/images/gallery/05.12.19/Dropped image 98.png';

export const _23_04_22 = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
    image21,
    image22,
    image23,
    image24,
    image
]

export const _23_05_22 = [
    image25,
    image26,
    image27,
    image28,
    image29,
    image30,
    image31,
    image32,
    image33,
    image34,
    image35,
    image36,
    image37,
    image38,
    image39,
    image40,
    image41,
    image42,
    image43,
    image44,
    image45,
    image46,
    image47,
    image48,
    image49,
    image50,
    image51
]

export const _08_10_21 = [
    image52,
    image53,
    image54,
    image55,
    image56,
    image57,
    image58,
    image59,
    image60,
    image61,
    image62,
    image63,
    image64,
    image65,
    image66,
    image67,
    image68,
    image69,
    image70,
    image71
]

export const _26_03_21 = [
    image72,
    image73,
    image74,
    image75,
    image76,
    image77,
    image78,
    image79
]

export const _25_09_20 = [
    image80,
    image81,
    image82,
    image83,
    image84,
    image85,
    image86,
    image87,
    image88,
    image89,
    image90,
    image91,
    image92,
    image93
]

export const _05_12_19 = [
    image94,
    image95,
    image96,
    image97,
    image98
]