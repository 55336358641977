import React, { useEffect } from 'react'
import '../../../custom.css';
import CircularText from '../../../common/Circular/CircularText';
import { Link } from 'react-router-dom';

function Hero() {

    const [start, setStart] = React.useState(false);
    
    useEffect(() => {
        setStart(true);
    }, []);

  return (
    <div className='mx-4 my-6'>
        <div className='font-bodoni font-black uppercase flex flex-col leading-[6rem] md:leading-[10rem] xl:leading-[16rem] justify-center'>
            <div className={`w-fit text-[8rem] md:text-[14em] xl:text-[22rem] transition-all ease-in duration-[1s] ${start ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'}`}>Oli</div>
            <div className={`w-fit text-[5rem] md:text-[11rem] xl:text-[19rem] text-stroke-0-5 text-white self-end relative left-8 xl:left-18 transition-all ease-in duration-[1s] ${start ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}>Oli</div>
            <div className={`w-fit text-[8rem] md:text-[14em] xl:text-[22rem] transition-all ease-in duration-[1s] ${start ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'}`}>Art</div>
            <div className={`w-fit text-[5rem] md:text-[11rem] xl:text-[19rem] text-stroke-0-5 text-white self-end relative left-8 xl:left-18 transition-all ease-in duration-[1s] ${start ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}>Art</div>
        </div>

        <div className='flex justify-between'>
            <div className='capitalize text-4xl lg:text-[4rem] font-thin relative top-8'>
                <Link to='/galerie'>Gallery</Link>
            </div>
            <div className='lato font-bold'>
                <CircularText text='23 Fevrier 2024 * '/>
            </div>
        </div>
    </div>
  )
}

export default Hero