import React, { useState } from 'react'
import img1 from '../../../assets/images/carroussel/img1.jpg';
import img2 from '../../../assets/images/carroussel/img2.jpg';
import img3 from '../../../assets/images/carroussel/img3.jpg';
import img4 from '../../../assets/images/carroussel/img4.jpg';
import img5 from '../../../assets/images/carroussel/img5.jpg';
import img6 from '../../../assets/images/carroussel/img6.jpg';
import img7 from '../../../assets/images/carroussel/img7.jpg';
import img8 from '../../../assets/images/carroussel/img8.jpg';
import img9 from '../../../assets/images/carroussel/img9.jpg';
import img10 from '../../../assets/images/carroussel/img10.jpg';
import img11 from '../../../assets/images/carroussel/img11.jpg';
import img12 from '../../../assets/images/carroussel/img12.jpg';
import img13 from '../../../assets/images/carroussel/img13.jpg';

function Slide() {

    const tabImages = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13];
    const [index, setIndex] = useState(1);

  return (
    <>
    <div className='mx-4 mt-40 mb-8 md:mt-56 font-bodoni uppercase lg:flex lg:items-center lg:justify-center'>

        <span className={'hidden flex-1 relative z-20 cursor-pointer lg:flex lg:text-3xl justify-center items-center max-w-10rem ' + (index !== 0 ? 'opacity-100' : 'opacity-0') } onClick={()=>{
        if (index !== 0)
        {
            setIndex(index - 1)
        }
        }}>Prev -</span>


        <div className={"max-w-[52rem] flex-1 mx-auto relative transition ease-in duration-500 "}
        style={{
            transform: `translateX(-${index * 100}%)`
        }}>

            <div className='flex relative z-10 '>
                {tabImages.map((img, i) => {
                    return (
                        <img key={i} src={img} alt="" srcset="" className={
                            "transition duration-500 w-full" +
                            (i === index ? `scale-100 opacity-100 ` : 'scale-0 opacity-0 rotate-0')
                        }/>
                    )
                })}
            </div>

        </div>

        <span className={'hidden flex-1 relative z-20 cursor-pointer lg:flex  lg:text-3xl justify-center items-center  max-w-10rem ' + (index !== tabImages.length - 1 ? 'opacity-100' : 'opacity-0') } onClick={()=>{
        if (index !== tabImages.length -1)
        {
            setIndex(index + 1)
        }
        }}>- Next</span>

        <div className='flex justify-evenly lg:hidden my-6'>
            <span className={'relative z-20 cursor-pointer ' + (index !== 0 ? 'opacity-100' : 'opacity-0') } onClick={()=>{
            if (index !== 0)
            {
                setIndex(index - 1)
            }
            }}>Prev -</span>
            <span className={'relative z-20 cursor-pointer ' + (index !== tabImages.length - 1 ? 'opacity-100' : 'opacity-0') } onClick={()=>{
            if (index !== tabImages.length -1)
            {
                setIndex(index + 1)
            }
            }}>- Next</span>
        </div>
    </div>
    </>
  )
}

export default Slide