import React from "react";
import logo from "../../../assets/images/oliart_logo.jpg";
import { Link } from "react-router-dom";

function Footer() {

	return (
		// This is the footer component. It will be used in the Home component.
		<div className="text-white bg-black flex flex-col justify-between pt-20 pb-4 gap-5 md:pt-10" >
			{/* first half */}
			<div className="flex flex-col-reverse gap-12 md:grid grid-cols-5">
				{/* image */}
				<div className="flex justify-center items-center  col-span-2" >
                    <Link to="/">
                        <img src={logo} alt="Logo Oliart" className="max-w-32"/>
                    </Link>
				</div>

				{/* menu */}
				<div className="flex flex-col gap-7 text-center text-2xl font-light col-span-3 justify-center items-center" >
					<div className=" flex flex-col gap-7 md:flex-row">
						<div className="uppercase" > <Link to="/">Evenements</Link></div>
						<div className="uppercase" > <Link to="/galerie">Expositions</Link></div>
					</div>
					<div className="flex flex-col gap-7 md:flex-row">
						<div className="uppercase" > <Link to="/artistes">Artistes</Link> </div>
						<div className="uppercase" > <Link to="/olivia">Olivia</Link> </div>
                        
					</div>
				</div>
			</div>

			{/* copyright */}
			<div className="flex items-center justify-center" >
				<div className="" >© 2024 Oliart. All rights reserved</div>
			</div>
		</div>
	);
}

export default Footer;