import React, {useState} from 'react'

function CardArtist({artiste, setArtist}) {
    const [hover, setHover] = useState(false)
  return (
    <div className='max-w-[23rem] max-h-[35rem] font-inter uppercase border border-gray-300 p-[2rem] flex flex-col justify-center items-center relative my-8' onMouseEnter={()=>{setHover(true)}} onMouseLeave={()=>{setHover(false)}}>
        <h1 className={`relative z-40 capitalize text-3xl text-center tranistion-all ease-in-out duration-[0.8s] ${hover && 'translate-y-[120px] text-white text-[3rem]'}`}>{artiste.name}</h1>
        <div className='flex justify-center rounded-lg m-[1rem_auto] max-h-[18rem] overflow-hidden transition-[all_.3s_ease-in-out]'>
            <img className={`w-full h-full tranistion-all ease-in-out duration-[0.8s] ${hover ? 'scale-0 opacity-0' : ''}`} src={artiste.img} alt="" />
        </div>
        <p className={`relative z-40 my-4 text-center tranistion-all ease-in-out duration-[0.8s] ${hover && '-translate-y-[120px] text-white '}`}>{artiste.description}</p>
        <span className={`relative z-40 uppercase p-[.7rem_4rem] bg-gray-600 tranistion-all ease-in-out duration-[0.8s] ${!hover ? 'text-white' : '-translate-y-[120px] bg-white text-gray-900 cursor-pointer'}`} onClick={()=>{
            setArtist()
        }}>Discover</span>
        <div className={`z-0 tranistion-all ease-in-out duration-[0.8s] overflow-hidden absolute top-0 left-0 w-full h-full ${hover ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-2'}`}>
            <img className='h-full w-full' src={artiste.img} alt="" />
        </div>
    </div>
    )
}

export default CardArtist