import dominique from '../assets/images/Dominique.jpg';
import tchief from '../assets/images/tchief.png'
import dz1 from '../assets/images/dz1.jpg'
import dz2 from '../assets/images/dz2.jpeg'
import t1 from '../assets/images/t1.jpeg'
import t2 from '../assets/images/t2.jpeg'

export const artists = [
    {
        id: 1,
        name: 'Dominique Zinkpè',
        img: dominique,
        role: 'Contemporary African Artist',
        description: "Dominique Zinkpè, artiste béninois contemporain, s'inspire du vaudou et de la culture africaine pour créer des œuvres riches en symbolisme.",
        parcours : "Dominique Zinkpè est un artiste plasticien contemporain originaire du Bénin, né en 1969 à Cotonou. Il a commencé sa carrière artistique dès son adolescence en apprenant les rudiments de la peinture au centre culturel français de Cotonou. Face à l'absence d'institutions artistiques formelles dans son pays, il a également exercé le métier de couturier tout en poursuivant sa passion pour l'art. Zinkpè a rapidement gagné en reconnaissance, remportant des prix tels que le Prix Jeune Talent Africain en 1993 et le Prix Uemoa en 2002. Son œuvre, comprenant des peintures, des sculptures et des installations, est fortement influencée par la culture béninoise, notamment les cérémonies vaudou, ainsi que par l'environnement quotidien africain. Il explore des thèmes tels que l'intimité, le sacré et le profane à travers une variété de matériaux et de techniques artistiques. Ses expositions ont été présentées dans le monde entier, et ses œuvres figurent dans de nombreuses collections publiques et privées.",
        style : "Le style artistique de Dominique Zinkpè est profondément influencé par la culture et l'histoire du Bénin, ainsi que par les traditions vaudou. Ses œuvres, qu'il s'agisse de peintures, de sculptures ou d'installations, explorent des thèmes tels que l'intimité, le sacré et le profane. Zinkpè utilise une variété de matériaux et de techniques artistiques, allant de la toile de jute aux voitures hors d'usage, en passant par les figurines traditionnelles utilisées dans le culte des jumeaux. Son style se caractérise par un mélange unique d'esthétique contemporaine et de références culturelles et religieuses africaines, créant des œuvres puissantes et évocatrices qui captivent le spectateur.",
        vision : "l s'efforce de capturer l'essence de la vie africaine en explorant des thèmes universels tels que l'intimité, le sacré et le profane. Zinkpè cherche à exprimer la complexité de ces sujets en mêlant des éléments contemporains à des références culturelles anciennes. Son art est une exploration des tensions entre tradition et modernité, spiritualité et matérialisme, créant ainsi des œuvres qui suscitent la réflexion et l'émotion chez le spectateur.",
        oeuvres: [dz1, dz2],
        label : ["Art contemporain africain", "Expressionnisme abstrait", "Art vaudou", "Sculpture africaine", "Peinture symbolique"]
    },
    {
        id: 2,
        name: 'Tchif',
        img: tchief,
        role: 'Peintre abstrait',
        description: "Tchif, adepte de l'expressionnisme abstrait, utilise des couleurs vives et des formes géométriques dans ses œuvres pour explorer les mystères de la vie avec une perspective méditative.",
        parcours : "Tchif, de son vrai nom Francis Nicaise Tchiakpe, est un artiste polyvalent dont le parcours artistique a débuté dès l'âge de 10 ans. Il a exploré différents médiums créatifs, notamment le dessin, la peinture et la photographie. Inspiré par l'abstraction expressionniste, il a développé son propre style en utilisant des techniques telles que le dripping et le recouvrement complet de ses toiles de peinture. Son travail a été largement exposé à travers le monde, notamment au Brésil, en Hollande, en France, en Allemagne, en Belgique, au Portugal, au Japon, aux États-Unis et au Venezuela. Ses œuvres sont présentes dans des collections renommées telles que Vallois, Leridon, Zinsou et Blachère.",
        style: "Le style artistique de Tchif est principalement caractérisé par une approche de l'abstraction expressionniste. Il utilise des techniques telles que le dripping, où la peinture est appliquée en laissant couler ou en éclaboussant la toile, et le recouvrement complet des toiles de peinture, connu sous le nom de \"All over\". Son travail se distingue par l'intensité de ses couleurs, y compris des couleurs fluorescentes, et par l'utilisation de formes géométriques et de symboles, tels que des cryptogrammes et des chiffres yorubas. Son style est méditatif et introspectif, invitant les spectateurs à explorer les mystères de la vie et de l'univers à travers ses œuvres.",
        vision: "La vision artistique de Tchif se concentre sur l'exploration des mystères de la vie et de l'univers à travers l'art abstrait. Pour lui, les symboles et les formes géométriques présents dans ses œuvres agissent comme des médiateurs entre l'homme et l'univers, reflétant les complexités et les lois qui régissent le monde. Il utilise la peinture comme un moyen de représenter l'essence invisible de la vie, capturant des éléments tels que le souffle, le mouvement et l'éclosion des couleurs. Son art offre une perspective méditative, invitant les spectateurs à réfléchir sur la beauté énigmatique et la férocité de l'existence humaine.",
        oeuvres: [t1, t2],
        label: ["Art méditatif", "Exploration des mystères de la vie", "Couleurs vives et formes géométriques", "Art plolyvalent"]
    },
]